var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Editor',{attrs:{"api-key":"dqf9j4v4f15h3fyy1cyee3mzm7cva8aw4blydcoyfzd9uul8","value":_vm.value,"init":{
        height: 500,
        menubar: false,
        plugins: ['link table charmap hr pagebreak nonbreaking advlist lists code image textcolor colorpicker'],
        toolbar: 'undo redo | bold italic underline strikethrough | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | link image responsivefilemanager |  code',
        automatic_uploads: true,
        image_advtab: true,
        file_picker_types: 'image',
        paste_data_images:true,
        relative_urls: false,
        remove_script_host: false,
        images_upload_handler: function (blobInfo, success, failure) {
            _vm.xhr.withCredentials = false;
            _vm.xhr.open('POST', _vm.base_url+'tinymce_upload');
            _vm.xhr.setRequestHeader('token', _vm.token);
            _vm.xhr.onload = function() {
                var json;
                if (_vm.xhr.status != 200) {
                    failure('HTTP Error: ' + _vm.xhr.status);
                    return;
                }
                json = JSON.parse(_vm.xhr.responseText);

                if (!json || typeof json.location != 'string') {
                    failure('Invalid JSON: ' + _vm.xhr.responseText);
                    return;
                }
                success(json.location);
            };
            _vm.formData.append('file', blobInfo.blob(), blobInfo.filename());
            _vm.xhr.send(_vm.formData);
        }
    }},on:{"input":function($event){return _vm.onInput($event)}}})}
var staticRenderFns = []

export { render, staticRenderFns }