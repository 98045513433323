<template>
    <Editor
        api-key="dqf9j4v4f15h3fyy1cyee3mzm7cva8aw4blydcoyfzd9uul8"
        :value="value"
        @input="onInput($event)"
        :init="{
            height: 500,
            menubar: false,
            plugins: ['link table charmap hr pagebreak nonbreaking advlist lists code image textcolor colorpicker'],
            toolbar: 'undo redo | bold italic underline strikethrough | fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | link image responsivefilemanager |  code',
            automatic_uploads: true,
            image_advtab: true,
            file_picker_types: 'image',
            paste_data_images:true,
            relative_urls: false,
            remove_script_host: false,
            images_upload_handler: function (blobInfo, success, failure) {
                xhr.withCredentials = false;
                xhr.open('POST', base_url+'tinymce_upload');
                xhr.setRequestHeader('token', token);
                xhr.onload = function() {
                    var json;
                    if (xhr.status != 200) {
                        failure('HTTP Error: ' + xhr.status);
                        return;
                    }
                    json = JSON.parse(xhr.responseText);

                    if (!json || typeof json.location != 'string') {
                        failure('Invalid JSON: ' + xhr.responseText);
                        return;
                    }
                    success(json.location);
                };
                formData.append('file', blobInfo.blob(), blobInfo.filename());
                xhr.send(formData);
            }
        }"
    />
</template>
<script>
    import Editor from '@tinymce/tinymce-vue'

    export default {
        name: 'editor_tinymce',
        props: ['value'],
        components: {
            Editor
        },
        data () {
            return {
                base_url: process.env.VUE_APP_API_URL,
                token: localStorage.getItem('token'),
                xhr: new XMLHttpRequest(),
                formData: new FormData(),
                document: document,
                reader: new FileReader()
            }
        },
        methods: {
            onInput (event) {
                this.$emit('input', event)
            }
        }
    }
</script>
